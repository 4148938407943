body {
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

.welcome {
  background-image:
    linear-gradient(180deg, rgba(19, 43, 140, 0.00) 0%, #0C1C56 100%),
    url('../src/img/frame519.png');
  background-size: cover, cover;
  background-position: center top, center top;
  background-repeat: no-repeat, no-repeat;
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  /* Mobil uyum için */
  align-items: center;
  justify-content: center;
  padding: 25px 20px 100px 20px;
}

.welcome-header {
  background-image:
    linear-gradient(180deg, rgba(19, 43, 140, 0.00) 0%, #0C1C56 100%),
    url('../src/img/frame519.png');
  background-size: cover, cover;
  background-position: center top, center top;
  background-repeat: no-repeat, no-repeat;
  width: 100%;
  /* Mobil uyum için */
  padding: 20px;
  height: 100vh;
}

.thank-you-card {
  background-color: #ffffff;
  color: #000000;
  padding: 60px 30px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  text-align: center;
}

@media (max-width: 1064px) {
  .welcome {
    flex-direction: column;
    min-height: auto;
    padding: 20px 10px;
    width: auto !important;
  }

}

.campaigns-container {
  justify-content: end !important;
}

.customer-review .campaigns-container .cover {
  left: 0 !important;
  right: auto !important;
}

.customer-review .campaigns-container .cover img {
  height: 340px;
}

.customer-review .campaigns-container .review-text {
  width: 60%;
}

.campaigns-container h2 {
  font-size: 40px;
  font-weight: 800;
  color: #fff;
}

.welcome .uk-container-large {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
}

@media (max-width: 1064px) {
  .welcome .uk-container-large {
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
  }
}

.welcome h1 {
  font-weight: 200;
  font-size: 41.1px;
  text-align: center;
  color: #fff;
  margin-top: 48px;
  margin-bottom: 80px;
  width: 100%;
  /* Başlığı genişlikte yay */
}

/* Mobil cihazlar için */
@media (max-width: 1064px) {
  .welcome h1 {
    font-size: 35px;
  }
}

.welcome h1 b {
  font-weight: 700;
}

.welcome .benefits {
  color: #fff;
  flex: 1;
  padding-right: 20px;
  /* Sağ boşluk */
}

.welcome .benefits h2 {
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 0;
  color: #fff;
}

.welcome .benefits p {
  font-size: 22px;
  font-weight: 200;
}

.welcome .benefits ul {
  padding-top: 20px;
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.welcome .benefits ul li {
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: 700;
}

.welcome .benefits ul li img {
  margin-right: 16px;
}

.welcome .benefits ul li:last-child {
  margin-bottom: 0;
}

.welcome .form {
  width: 500px;
  position: relative;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.13);
  border: 1px solid #063A75;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin-left: 20px;
  float: right;
  right: 0 !important;
}


/* Mobil cihazlar için */
@media (max-width: 1064px) {
  .welcome .form {
    text-align: center;
    width: 100%;
    float: none;
    margin: 0 auto !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }

  .welcome .benefits {
    width: calc(100% - 30px);
  }

  .prods-list ul {
    justify-content: center;
  }

  .prods-list ul li:nth-child(1),
  .prods-list ul li:nth-child(2),
  .prods-list ul li:nth-child(3),
  .prods-list ul li:nth-child(4),
  .prods-list ul li:nth-child(5),
  .prods-list ul li:nth-child(6) {
    left: 0 !important;
    margin-top: 50px !important;
    transform: translate(0) !important;
  }

  .prods-list ul li:nth-child(1) {
    margin-top: 0 !important;
  }

  .uk-card.uk-card-body.storie {
    margin-top: 50px !important;
  }

  .welcome-header {
    width: auto !important;
  }

  .thank-you-card {
    padding: 60px 20px !important;
    width: 89% !important;
    margin-top: 125px;
  }
}

.comments .container {
  width: 100%;
  overflow: hidden;
  /* Taşan görselleri gizle */
  position: relative;
}

.comments .image-wrapper {
  display: inline-flex;
  /* Görsellerin yan yana durması için */
  animation: scroll 20s linear infinite;
  /* Kaydırma animasyonu */
}

.comments img {
  width: 387px;
  /* Görsel genişliği */
  height: 110px;
  /* Orantılı yükseklik */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin: 20px;
  border-radius: 10px;
  /* Görseller arası boşluk */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
    /* Başlangıç noktası */
  }

  100% {
    transform: translateX(-100%);
    /* Son nokta */
  }
}

.welcome .form .last-users {
  align-self: stretch;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: radial-gradient(50% 50% at 50% 50%, #183fc7, #112981);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  text-align: left;
  color: #ffffff;
}

.welcome .form .last-users .first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.welcome .form .last-users .last {
  position: relative;
  color: #ffffff;
}

.welcome .form .f-group {
  gap: 32px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcome .form .f-group .title {
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-size: 24px;
  margin-top: 20px;
}

.welcome .form .f-group .f-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  text-align: left;
  font-size: 14px;
  color: #757575;
}

.welcome .form .f-group .f-container .uk-inline {
  width: 100%;
  height: auto;
}

.welcome .form .f-group .f-container .uk-inline .custom-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.welcome .form .f-group .f-container .uk-inline .uk-input {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  border-radius: 4px;
  height: 60px;
  background-color: #fff;
  padding-left: 48px !important;
  /* İkon için yer bırak */
  font-size: 18px;
}

.button {
  margin-top: 30px;
  align-self: stretch;
  box-shadow: 0px 4px 100px #122981;
  border-radius: 4px;
  background: linear-gradient(-90deg, #1841CC, #122981);
  padding: 16px 62px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: all .2s;
  border: none;
  color: #ffffff;
}

.button:hover {
  box-shadow: 0px 4px 100px #1841CC;
  background: linear-gradient(-90deg, #1841CC, #122981);
}




.why-us {
  background-image: url('data:image/svg+xml;utf8,<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M50 50C50 44.477 54.477 40 60 40C65.523 40 70 44.477 70 50C70 55.523 65.523 60 60 60C60 65.523 55.523 70 50 70C44.477 70 40 65.523 40 60C40 54.477 44.477 50 50 50ZM10 10C10 4.477 14.477 0 20 0C25.523 0 30 4.477 30 10C30 15.523 25.523 20 20 20C20 25.523 15.523 30 10 30C4.477 30 0 25.523 0 20C0 14.477 4.477 10 10 10ZM20 18C24.418 18 28 14.418 28 10C28 5.582 24.418 2 20 2C15.582 2 12 5.582 12 10C12 14.418 15.582 18 20 18ZM60 58C64.418 58 68 54.418 68 50C68 45.582 64.418 42 60 42C55.582 42 52 45.582 52 50C52 54.418 55.582 58 60 58Z" fill="black" fill-opacity="0.03"/></svg>');
  background-size: 70px 70px;
  background-repeat: repeat;
  padding: 50px 0;
}

.why-us h2 {
  color: #063A75;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  margin-top: 102px;
  position: relative
}

.why-us h2::after {
  content: "";
  width: 150px;
  height: 3px;
  background: #33E146;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
}

.why-us .boxes {
  margin-top: 50px;
  margin-bottom: 50px;

}

.why-us .boxes .uk-card {
  border-radius: 8px;
  background: #FFF;
  height: 100%;
  flex: 1;
  width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px;
  box-sizing: border-box;
  gap: 16px;
  text-align: left;
  font-size: 20px;
}


.why-us .boxes .uk-card .icon {
  border-radius: 4px;
  background: linear-gradient(180deg, #0b6ddb, #063a75);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  height: 32px;
  width: 32px;

}

.why-us .boxes .uk-card .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.why-us .boxes .uk-card .content h3 {
  align-self: stretch;
  position: relative;
  line-height: 32px;
  color: #063a75 !important;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.why-us .boxes .uk-card .content p {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #a8a8b2;
}

.why-us .cta {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #0b66cd;
  padding: 19px 82px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  max-width: 310px;
  margin: 80px auto;
  transition: all .2s;
  display: block;
  /* Blok seviyesinde öğe yapar */
  width: fit-content;
  /* İçeriğin genişliğine göre ayarlar */
  margin: 0 auto;
  /* Yatay olarak ortalar */
}

.why-us .cta:hover {
  background-color: #1177ec;
}

.button-container {
  text-align: center;
}


.customer-review {
  width: 100%;
  position: relative;
  background: linear-gradient(90deg, #063367, #0b66cd);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 18px;
  color: #fff;
  height: 350px;
  padding: 20px 0;
}

.customer-review .uk-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.customer-review .review-text {
  width: 50%;
  line-height: 150%;
  z-index: 1;
  text-align: left;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.customer-review .review-text .author {
  text-align: left;
  font-weight: bold;
  margin-top: 20px;
}

.customer-review .cover {
  position: absolute !important;
  right: 0 !important;
  bottom: -1px !important;
}

.customer-review .cover img {
  width: 100%;
  height: 403px;
}

/* Mobil cihazlar için */
@media (max-width: 1064px) {
  .customer-review {
    display: block;
    height: auto;
    padding: 0;
    margin-top: -50px;
    /* Yükseklik ayarı */
  }

  .customer-review .uk-container {
    display: block;
    padding: 0;
  }

  .customer-review .cover {
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .customer-review .cover img {
    width: 100%;
    height: auto;
  }

  .customer-review .review-text {
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  .customer-review .review-text .author {
    text-align: center;
  }
}


.analyses {
  background-image: url('data:image/svg+xml;utf8,<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M50 50C50 44.477 54.477 40 60 40C65.523 40 70 44.477 70 50C70 55.523 65.523 60 60 60C60 65.523 55.523 70 50 70C44.477 70 40 65.523 40 60C40 54.477 44.477 50 50 50ZM10 10C10 4.477 14.477 0 20 0C25.523 0 30 4.477 30 10C30 15.523 25.523 20 20 20C20 25.523 15.523 30 10 30C4.477 30 0 25.523 0 20C0 14.477 4.477 10 10 10ZM20 18C24.418 18 28 14.418 28 10C28 5.582 24.418 2 20 2C15.582 2 12 5.582 12 10C12 14.418 15.582 18 20 18ZM60 58C64.418 58 68 54.418 68 50C68 45.582 64.418 42 60 42C55.582 42 52 45.582 52 50C52 54.418 55.582 58 60 58Z" fill="black" fill-opacity="0.03"/></svg>');
  background-size: 70px 70px;
  background-repeat: repeat;
  padding-top: 50px;
  position: relative;
}

.analyses h2 {
  color: #063A75;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  margin-top: 102px;
  position: relative
}

.analyses h2::after {
  content: "";
  width: 150px;
  height: 3px;
  background: #33E146;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
}

.table-content {
  margin-top: 80px;
  height: 585px;
  padding: 49px 33px 50px 43px;
  background-color: #fff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
  -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
  -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
  overflow: auto;
}

.table-content table {
  width: 100%;
}

.table-content table thead {
  background-color: #eeeeee;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -ms-border-radius: 10px 10px 0 0;
  -o-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}

.table-content table th {
  color: #818596;
  font-size: 14px;
  font-weight: 800;
  padding: 21px 15px 20px;
  text-transform: uppercase;
}

.table-content table th:first-child {
  padding-left: 17px;
  -webkit-border-radius: 15px 0 0 0;
  -moz-border-radius: 15px 0 0 0;
  -ms-border-radius: 15px 0 0 0;
  -o-border-radius: 15px 0 0 0;
  border-radius: 15px 0 0 0;
}

.table-content table th:last-child {
  padding-right: 17px;
  -webkit-border-radius: 0 15px 0 0;
  -moz-border-radius: 0 15px 0 0;
  -ms-border-radius: 0 15px 0 0;
  -o-border-radius: 0 15px 0 0;
  border-radius: 0 15px 0 0;
}

.table-content table td {
  color: #818181;
  font-size: 14px;
  font-weight: 600;
  padding: 18px 15px 14px;
  text-align: center;
  border-bottom: 1px solid #ededed;
}

.table-content table td.num {
  color: #242424;
  font-weight: 700;
}

.table-content table td.cat {
  color: #1f1f1f;
}

.table-content table td.stat {
  color: #4266ff;
  font-weight: 800;
}

.table-content table td.stat.clr2 {
  color: #ff8828;
}

.table-content table td.price {}

.table-content table td.price-td .price {
  color: #0b1228;
  background-color: #33e146;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  padding: 0 16px;
  position: relative;
  height: 40px;
  line-height: 40px;
  margin-top: -23px;
  position: relative;
  top: 10px;
  font-weight: 700;
  min-width: 80px;
}

.table-content table td.price-td .price.clr2 {
  background-color: #ff243e;
  color: #fff;
}

.table-content table td.price-td .price strong {
  font-weight: 800;
}

.table-content table td.price:before,
.table-content table td.flt {
  color: #33e146;
  font-weight: 800;
}

.table-content table td.flt img {
  margin-left: 5px;
}

.table-content table td.flt.clr2 {
  color: #ff243e;
}

.analyses-info {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  box-sizing: border-box;
  gap: 16px;
  text-align: left;
  font-size: 16px;
  color: #a8a8b2;
  margin-top: 50px;
  margin-bottom: -38px;
}

.icon-wrapper {
  border-radius: 2.78px;
  background: linear-gradient(180deg, #0b6ddb, #063a75);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 7px;
  width: 18.1px;
  height: 18.1px;
}

.info-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  line-height: 24px;
}









.performance {
  background-image: url('../src/img/frame520.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 144px 0;
}


.products-section .container {
  max-width: 1500px;
}

.title-rw {
  text-align: center;
  position: relative;
  margin-bottom: 67px;
}

.title-rw h2 {
  color: #33E146;
  font-size: 40px;
  font-weight: 700;
}

.prods-list {
  padding-top: 50px
}

.prods-list ul {
  display: flex;
  flex-wrap: wrap;
}

.prods-list ul li {
  text-align: center;
  position: relative;
}

.prods-list ul li>h3 {
  color: #ffffff;
  font-size: 25.2px;
  font-weight: 700;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.prods-list ul li>p {
  color: #ffffff;
  font-size: 21.6px;
  font-weight: 600;
}

.prods-list ul li>p strong {
  font-weight: 800;
}

.prods-list ul li:nth-child(1) {
  left: 25px;
}

.prods-list ul li:nth-child(2) {
  margin-top: 200px;
  left: 50px;
}

.prods-list ul li:nth-child(3) {
  margin-top: 0;
  left: 88px;
}

.prods-list ul li:nth-child(4) {
  margin-top: 198px;
  left: 83px;
}

.prods-list ul li:nth-child(5) {
  margin-top: 0;
  left: 73px;
}

.prods-list ul li:nth-child(6) {
  margin-top: 50px;
  left: 50%;
  transform: translate(-50%);
}

.comments {
  position: relative;
  width: 100%;
  /* top: -50px; */
}

.comments .slide {
  position: absolute;
  z-index: 89;
}

.welcome .uk-grid-collapse {
  display: flex;
}

.stories {
  background-image: url('../src/img/frame578.png');
  background-size: 100% auto;
  background-color: #0b1228;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100% !important;
  height: auto;
  padding: 150px 20px;
  box-sizing: border-box;
}

.why-card {
  width: 32% !important;
}

.why-us .boxes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.why-card {
  margin-bottom: 30px;
}

.stories h2 {
  color: #FFF;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  margin-top: 100px;
  position: relative
}

.stories h2::after {
  content: "";
  width: 150px;
  height: 3px;
  background: #33E146;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
}

.stories .s-boxes {
  margin-top: 100px;
}

.storie {
  height: 100%;
  flex: 1;
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 16px;
  box-sizing: border-box;
  gap: 16px;
  text-align: center;
  font-size: 20px;
  color: #063a75;
  font-family: 'Open Sans';
}

.storie-img {
  width: 76px;
  position: relative;
  border-radius: 5.85px;
  height: 76px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.storie h3 {
  align-self: stretch;
  font-size: 20px;
  line-height: 24px;
  display: inline-block;
  color: #063a75;
  text-align: center;
  font-weight: 600;
}

.storie p {
  color: #a8a8b2;
  font-size: 16px;
}

.stars {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 30px;
}

.stars .star {
  width: 50px !important;
  position: relative;
  height: 50px !important;
}

footer {
  margin-top: 200px;
  text-align: center;
}

footer .logo {
  margin-top: 200px;
}

footer .cta-text {
  font-size: 36.1px;
  letter-spacing: 2px;
  color: #f0f1fc;
  margin-top: 20px;
}

footer .cta-info {
  font-size: 17.1px;
  line-height: 24.35px;
  color: #fff;
  margin-top: 20px;
}

footer .cta {
  align-self: stretch;
  box-shadow: 0px 4px 100px #122981;
  border-radius: 4px;
  background: linear-gradient(-90deg, #1841CC, #122981);
  padding: 16px 62px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: all .2s;
  margin-top: 150px;
  color: #fff;
  border: none;
  display: block;
  /* Blok seviyesinde öğe yapar */
  width: fit-content;
  /* İçeriğin genişliğine göre ayarlar */
  margin: 0 auto;
  /* Yatay olarak ortalar */
}

footer .cta:hover {
  box-shadow: 0px 4px 100px #1841CC;
  background: linear-gradient(-90deg, #1841CC, #122981);
}

footer .copyright {
  font-size: 17.1px;
  color: #cbd9e0;
  margin-top: 50px;
}

@media (max-width: 600px) {
  .welcome .uk-grid-collapse {
    flex-wrap: wrap;
  }

  .why-us .boxes {
    flex-wrap: wrap;
  }

  .why-card {
    width: 100% !important;
  }

  .comments .slide {
    overflow: hidden;
  }

  .stories .s-boxes {
    flex-wrap: wrap;
  }

  .performance {
    padding-bottom: 150px !important;
  }

  .comments img {
    width: 300px !important;
    height: 85px !important;
  }

  .customer-review .campaigns-container .cover img {
    display: none;
  }

  .customer-review .campaigns-container .review-text {
    width: 100%;
    margin-top: 50px;
  }
}